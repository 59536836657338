import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import About from "../Pages/About";
import ContactPage from "../Pages/ContactPage";
import Home01 from "../Pages/Homes/Home01";
import Services01 from "../Pages/Services/Services01";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import Blog from "../Pages/Blog";
import StumpGrinding from "../Pages/Services/StumpGrinding";
import LandClearing from "../Pages/Services/LandClearing";
import TreeTrimming from "../Pages/Services/TreeTrimming";
import TreeRemoval from "../Pages/Services/TreeRemoval";
import TreeHealthConsulting from "../Pages/Services/TreeHealthConsulting";
import ArboristConsultation from "../Pages/Services/ArboristConsultation";
import Careers from "../Pages/Careers";
import React from "react";
import { BlogPost } from "../Layouts/BlogPost";
import TreePruning from "../Pages/Services/TreePruning";
import EmergencyTreeService from "../Pages/Services/EmergencyTreeService";
import TreePlanting from "../Pages/Services/TreePlanting";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <Home01 />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/services",
        element: <Services01 />,
      },
      {
        path: "/stump-grinding",
        element: <StumpGrinding />,
      },
      {
        path: "/tree-trimming",
        element: <TreeTrimming />,
      },
      {
        path: "/tree-pruning",
        element: <TreePruning />,
      },
      {
        path: "/tree-planting",
        element: <TreePlanting />,
      },
      {
        path: "/tree-removal",
        element: <TreeRemoval />,
      },
      {
        path: "/tree-health-consulting",
        element: <TreeHealthConsulting />,
      },
      {
        path: "/land-clearing",
        element: <LandClearing />,
      },
      {
        path: "/emergency-tree-services",
        element: <EmergencyTreeService />,
      },
      {
        path: "/arborist-consultations",
        element: <ArboristConsultation />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/blog/:id",
        element: <BlogPost />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
