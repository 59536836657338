export const posts = [
    {
        id: '1',
        title: 'The Essentials of Tree Pruning in Hartford',
        metaTitle: 'Tree Pruning Essentials in Hartford | Hartford Tree Services Blog',
        excerpt: 'Discover the key aspects of tree pruning to keep your trees healthy and aesthetically pleasing. Learn best practices from Hartford experts.',
        slug: 'essentials-of-tree-pruning-hartford',
        tags: ['tree pruning', 'Hartford', 'tree care'],
        metaDescription: 'Learn about essential tree pruning techniques and tips to maintain the health and beauty of your trees in Hartford. Read more on our blog.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'Navigating Emergency Tree Removal in Hartford',
        metaTitle: 'Handling Emergency Tree Removal | Hartford Tree Services Blog',
        excerpt: 'Emergency tree removal can be challenging. Our blog post guides you through safely managing such situations in Hartford.',
        slug: 'emergency-tree-removal-hartford',
        tags: ['emergency tree removal', 'tree safety', 'Hartford'],
        metaDescription: 'Understand how to approach emergency tree removal in Hartford safely. Get expert advice from our latest blog post.',
        lastModified: new Date('2023-12-15').toISOString()
    },
    {
        id: '3',
        title: 'Sustainable Tree Care Practices in Hartford',
        metaTitle: 'Eco-Friendly Tree Care in Hartford | Hartford Tree Services Blog',
        excerpt: 'Sustainable tree care is vital for our environment. Learn about eco-friendly practices in tree maintenance and care in Hartford.',
        slug: 'sustainable-tree-care-hartford',
        tags: ['sustainable tree care', 'eco-friendly', 'Hartford'],
        metaDescription: 'Explore sustainable practices in tree care and how they contribute to a healthier environment in Hartford. Read our insightful blog post for more information.',
        lastModified: new Date('2023-12-20').toISOString()
    },
    {
        id: '4',
        title: 'Maximizing the Health of Your Trees in Hartford',
        metaTitle: 'Tips for Optimal Tree Health in Hartford | Hartford Tree Services Blog',
        excerpt: 'Keeping your trees healthy is key to a beautiful landscape. Our blog post shares essential tips for maintaining tree health in Hartford’s unique climate.',
        slug: 'maximizing-tree-health-hartford',
        tags: ['tree health', 'tree maintenance', 'Hartford'],
        metaDescription: 'Learn the best practices for maintaining the health of your trees in Hartford. Our blog provides expert advice for tree care enthusiasts and homeowners alike.',
        lastModified: new Date('2024-1-04').toISOString()
    },
]
