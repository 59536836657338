import React from "react";
import { SiteData as siteData, SiteData } from "../../Constants/siteData";
import { Box, Link } from "@chakra-ui/react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_above">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer_widget footer_widget_padding">
                <h2 className="widget_title">
                  {SiteData.homepage.footerHeading}
                </h2>
                <p>{SiteData.homepage.footerText()}</p>
                <p>{SiteData.homepage.footerText2}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <h2 className="widget_title">Our Services</h2>
                <div className="footer_nav">
                  <ul className="footer_menu">
                    {SiteData.homepage.featuredServices.map(
                      ({ path, linkTitle }) => {
                        return (
                          <li className="menu-item" key={linkTitle}>
                            <Link href={path}>{linkTitle}</Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <h2 className="widget_title">Useful Links</h2>
                <div className="footer_nav">
                  <ul className="footer_menu">
                    {SiteData.navLinks.map(({ title, href }) => {
                      return (
                        <li className="menu-item" key={title}>
                          <a href={href}>{title}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer_widget">
                {/*<h4 className="widget_title">*/}
                {/*    Address*/}
                {/*</h4>*/}
                <ul>
                  <li>
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                    <span>{SiteData.hoursOfOperation}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="footer_bottom_inner">
            <Box
              display={{ sm: "flex", base: "flex", lg: "initial" }}
              justifyContent={{ sm: "center", base: "center", lg: "initial" }}
              w={{ sm: "full", base: "full", lg: "initial" }}
              alignItems={"center"}
            >
              <div className="logo">
                <a href="index">
                  <img src="images/logo2.png" alt="Logo" />
                </a>
              </div>
            </Box>
            <div className="copyright">
              <p>
                &copy; {new Date().getFullYear()} {siteData.footerText}
              </p>
            </div>
            <div className="totop">
              <a href="#" aria-label="Back to top">
                <i className="ion-ios-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
