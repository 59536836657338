import React from "react";

const TestimonialCard01 = ({ data }) => {
  const { avatarUrl: img, name, title, quote } = data;
  return (
    <div className="item">
      <div className="testibox1">
        <div className="testibox_inner">
          <div className="testi-content">
            <ul>
              <li className="text">Rating:</li>
              <li>
                <i className="fa fa-star"></i>
              </li>
              <li>
                <i className="fa fa-star"></i>
              </li>
              <li>
                <i className="fa fa-star"></i>
              </li>
              <li>
                <i className="fa fa-star"></i>
              </li>
              <li>
                <i className="fa fa-star"></i>
              </li>
            </ul>
            <p>{quote}</p>
          </div>
          <div className="testi-top">
            <div className="testi-img">
              <img src={img} alt="" />
            </div>
            <div className="testi-info">
              <h3>{name}</h3>
              <span>{title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard01;
