import React, {useEffect, useState} from "react";
import {Text} from "@chakra-ui/react";
import useBlogPosts from "../Hooks/useBlogPosts";
import {useLocation} from "react-router-dom";
import {BLOCKS, MARKS} from '@contentful/rich-text-types';
import BlogDetails from "../Pages/Blogs/BlogDetails";
import {SEO} from "../Components/SEO/SEO";

export const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Text fontWeight={'bold'}>{text}</Text>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
};

export const BlogPost = () => {
    const [activePost, setActivePost] = useState({});
    const {posts} = useBlogPosts()
    const {pathname} = useLocation()
    useEffect(() => {
        setActivePost(posts.find((post) => pathname.includes(post.slug)))
    }, [posts, pathname, setActivePost])
    if (!activePost) return <></>
    return (
        <>
            <SEO/>
            <BlogDetails post={activePost}/>
        </>
    )
}