import React from "react";
import hartfordTreeServiceMeta from "../assets/images/meridenmeta.jpg";
import bgImage from "../assets/images/overlandpark5.jpg";

import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    Heading,
    Hide,
    Link,
    List,
    ListItem,
    Stack,
    Text,
    UnorderedList
} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeDoor, GiTreeRoots,} from "react-icons/gi";
import {
    FaConciergeBell,
    FaCut,
    FaPhoneVolume,
    FaRegCalendarAlt,
    FaRegHandScissors,
    FaShieldAlt,
    FaStar,
} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople,} from "react-icons/md";
import {AiOutlineScissor} from "react-icons/ai";
import {posts} from "./posts";
import {faqs} from "./faqs";

const phoneNumber = "(860) 901-2268";
const telLink = "tel:8609012268";
const phoneNumberWithDashes = "860-901-2268";

export const SiteData = {
    hoursOfOperation: "24/7",
    city: "Hartford, CT",
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    buttonCtaText: () => (
        <Flex>
            Looking for Expert Tree Services? Book Your&nbsp;
            <Text textDecoration={"underline"} display={"inline"}>
                Free Consultation Today!
            </Text>
        </Flex>
    ),
    keywords:
        "Tree Care, services, professional, affordable, experienced, pruning, Hartford, CT.",
    footerText: "Hartford Tree Services, All rights reserved.",
    ogImage: hartfordTreeServiceMeta,
    navLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services/",
        },
        {
            title: "Blog",
            href: "/blog/",
        },
        {
            title: "About",
            href: "/about/",
        },
        {
            title: "Contact Us",
            href: "/contact/",
        },
        {
            title: "Careers",
            href: "/careers/",
            hideFromTopNav: true,
        },
    ],
    signupForm: {
        intro: () => (
            <Text>
                Welcome to Hartford Tree Services, your trusted tree service provider in
                Hartford, CT. Our team of expert arborists is well-versed in the unique
                tree species and landscaping challenges of the Hartford area, offering a
                wide range of tree care services. Contact us at{" "}
                <Link href={telLink} textDecoration={"underline"}>
                    (860) 555-1234
                </Link>{" "}
                for professional tree care.
            </Text>
        ),
        servicesTitle: "Our Services:",
    },
    homepage: {
        bgImage: bgImage,
        url: "https://www.hartfordtreeservicect.com",
        metaData: {
            title:
                "Hartford Tree Service CT: Your Local Tree Care Experts | Call Today",
            description:
                "Hartford Tree Service CT offers professional tree care services. Contact us for expert tree maintenance in Hartford, Connecticut.",
        },
        h1: "Professional Tree Care Services in Hartford, CT",
        subtitle: "Complete Tree Care - Trimming, Removal, Health Consulting",
        servicesTitle: "Why Hartford Residents Choose Our Tree Services",
        values: [
            {
                id: 0,
                number: "10+ Years",
                name: "Experience in Hartford",
                icon: FaRegCalendarAlt, // Example icon representing experience over time
            },
            {
                id: 1,
                number: "100%",
                name: "Licensed & Insured",
                icon: FaShieldAlt, // Example icon representing safety and assurance
            },
            {
                id: 4,
                number: "24/7",
                name: "Emergency Services",
                icon: FaPhoneVolume, // Example icon representing round-the-clock availability
            },
            {
                id: 5,
                number: "Top Rated",
                name: "Top Reviews in Hartford",
                icon: FaStar, // Example icon representing positive community reviews
            },
        ],
        content: [
            <>
                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Hartford Tree Services: Expert Tree Care in Hartford, CT
                    </Heading>
                    Welcome to Hartford Tree Services, your go-to destination for expert
                    tree care in Hartford, Connecticut. Our team, comprised of skilled
                    arborists and tree care professionals, is dedicated to providing a
                    comprehensive range of services tailored to enhance the health and
                    aesthetic appeal of your trees.
                    <br/>
                    Our expertise lies in precision <a href={"https://www.hounslowtreesurgeons.com/"} target={'_blank'}
                                                       rel={'noreferrer'}>tree trimming</a>, where we focus on
                    improving the shape, structure, and overall health of your trees.
                    Regular trimming not only fosters healthy growth but also enhances the
                    visual appeal of your landscape. Additionally, we offer stump removal
                    services, ensuring the efficient and safe removal of unsightly tree
                    stumps from your property, providing a clean and seamless look to your
                    outdoor space.
                    <br/>
                    Understanding that each tree is unique, our tree care professionals
                    provide personalized maintenance plans, addressing specific needs such
                    as fertilization and disease control. Whether you're a homeowner or a
                    business owner, you can rely on Hartford Tree Services for top-quality
                    tree care, with a commitment to exceeding your expectations. Contact
                    us today to schedule a consultation and let us be your trusted partner
                    in maintaining the beauty and health of Hartford's landscapes.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Our Full Spectrum of Tree Care Services
                    </Heading>
                    Explore our extensive tree care services, each tailored to meet your
                    specific needs:
                    <UnorderedList>
                        <ListItem>
                            <Link href="/tree-trimming/">
                                Tree Trimming:
                            </Link>{" "}
                            Meticulous trimming to promote healthy growth and maintain tree
                            structure.
                        </ListItem>
                        <ListItem>
                            <Link href="/tree-pruning/">
                                Tree Pruning:
                            </Link>{" "}
                            Careful pruning to enhance the health and appearance of your trees.
                        </ListItem>
                        <ListItem>
                            <Link href="/stump-grinding-removal/">
                                Stump Grinding and Removal:
                            </Link>{" "}
                            Efficient removal of unsightly stumps to improve landscape
                            appearance and safety.
                        </ListItem>
                        <ListItem>
                            <Link href="/tree-health-consulting/">
                                Tree Health Consulting:
                            </Link>{" "}
                            Expert evaluations to ensure your trees’ vitality and address any
                            health concerns.
                        </ListItem>
                        <ListItem>
                            <Link href="/emergency-tree-services/">
                                Emergency Tree Services:
                            </Link>{" "}
                            Rapid response for urgent tree care needs, including storm damage
                            and hazard prevention.
                        </ListItem>
                        <ListItem>
                            <Link href="/arborist-consultations/">
                                Arborist Consultations:
                            </Link>{" "}
                            In-depth advice from certified arborists on tree care, planting,
                            and maintenance.
                        </ListItem>
                        <ListItem>
                            <Link href="/land-clearing/">Landscaping Services:</Link>{" "}
                            Integrating <a href={"https://www.treeservicefranklinma.com/"} target={"_blank"}
                                           rel={"noreferrer"}>tree service</a> with overall landscaping to create
                            harmonious outdoor spaces.
                        </ListItem>
                        <ListItem>
                            <Link href="/eco-friendly-tree-solutions/">
                                Eco-Friendly Tree Solutions:
                            </Link>{" "}
                            Implementing sustainable practices for the well-being of the
                            environment and your trees.
                        </ListItem>
                    </UnorderedList>
                    Our Hartford team is committed to delivering top-quality tree care,
                    utilizing the latest techniques and equipment.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Our Dedication to Excellence and Sustainability
                    </Heading>
                    At Hartford Tree Services, our distinctive mark is the unwavering
                    commitment to excellence and sustainable tree care practices. We
                    firmly believe in nurturing the inherent natural beauty of Hartford
                    while simultaneously preserving the health of its urban forest. Our
                    approach seamlessly integrates quality, reliability, and environmental
                    stewardship, reflecting a dedication to responsible tree care.
                    <br/>
                    Our team understands the significance of trees in enhancing the
                    overall well-being of the environment and community. Therefore, when
                    you choose Hartford Tree Services, you are choosing a partner who is
                    deeply committed to the long-term health and vitality of your trees.
                    Our practices prioritize not only the immediate well-being of your
                    trees but also contribute to the sustainability of Hartford's
                    ecosystem.
                    <br/>
                    By selecting our services, you join hands with a team that values
                    quality workmanship, reliability in service delivery, and a strong
                    commitment to environmental responsibility. Hartford Tree Services is
                    more than a service provider; we are a dedicated partner in ensuring
                    the enduring health and beauty of Hartford's green spaces. Trust us to
                    safeguard and enhance the natural elements that make Hartford a unique
                    and thriving community.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Why Hartford Chooses Us for Tree Services
                    </Heading>
                    Our clients in Hartford choose us for our:
                    <UnorderedList>
                        <ListItem>
                            Deep-rooted expertise in a range of tree care services.
                        </ListItem>
                        <ListItem>
                            Commitment to eco-friendly and sustainable practices.
                        </ListItem>
                        <ListItem>
                            Reliable, timely, and professional service delivery.
                        </ListItem>
                        <ListItem>
                            Customized solutions to meet unique landscape needs.
                        </ListItem>
                        <ListItem>
                            Competitive pricing for high-quality tree care services.
                        </ListItem>
                    </UnorderedList>
                    Join the multitude of satisfied customers who trust Hartford Tree
                    Services for enhancing their natural surroundings. We take pride in
                    our reputation as Hartford's go-to tree care provider.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Contact Hartford Tree Services Today
                    </Heading>
                    Ready to transform your landscape? For top-tier tree care in Hartford,
                    CT, <Link href={"tel:8605551234"}>call us at 860-555-1234</Link>.
                    Schedule a consultation and discover why we are Hartford’s preferred
                    tree care provider. Let us be your partner in maintaining the health
                    and beauty of your trees.
                </Text>

                <Button
                    as={"a"}
                    href={telLink}
                    bg={"#17b644"}
                    fontSize={{base: "20px", md: "23px"}}
                    color={"white"}
                    fontWeight={"bold"}
                    _hover={{bg: "#06c03b", color: "white", cursor: "pointer"}}
                    p={"16px 30px"}
                    borderRadius={0}
                    mt={"2rem"}
                    lineHeight={"26px"}
                    minH={"66px"}
                    width={"100%"}
                    maxW={"400px"}
                >
                    <i className="fa fa-phone" aria-hidden="true"/>
                    &nbsp;&nbsp;Call for <Hide below="md">Immediate</Hide> Assistance
                </Button>
            </>,
        ],
        additionalContent:
            <>
                <Container maxW="container.xl">
                    <Box as="section" pt={10}>
                        <Text mb={4}>
                            When it comes to Professional Tree Care in Hartford, Hartford Tree Service
                            emerges as a trusted leader. Our dedicated team is committed to providing top-notch tree
                            care services
                            to enhance the health and beauty of your trees. Discover the difference with Hartford Tree
                            Service,
                            where expertise meets a passion for preserving the natural beauty of your landscape.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Our Tree Care Expertise
                        </Heading>
                        <Text mb={4}>
                            With a profound understanding of local flora and tree health, our team at Hartford Tree
                            Service is
                            dedicated to delivering high-quality tree care services. We employ the latest techniques and
                            arborist
                            practices to ensure the well-being and longevity of your trees.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Comprehensive Tree Care Services
                        </Heading>
                        <Text mb={4}>
                            Our diverse range of tree care services caters to both residential and commercial
                            properties,
                            promoting the vitality and beauty of your trees throughout the year:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-pruning/">Tree Pruning:</Link></strong> Enhance the
                                structure
                                and health of your trees with our professional pruning services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-removal/">Tree Removal:</Link></strong> Safely and
                                efficiently
                                remove unwanted or hazardous trees from your property.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-trimming/">Tree Trimming:</Link></strong> Maintain
                                the aesthetic
                                appeal of your trees and promote healthy growth with regular trimming.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/stump-grinding/">Stump Grinding:</Link></strong> Remove
                                unsightly tree
                                stumps to enhance the visual appeal of your landscape.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-health-consulting/">Tree Health
                                    Consulting:</Link></strong> Ensure
                                the well-being of your trees with expert consulting services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-planting/">Tree Planting:</Link></strong> Add beauty
                                and value to
                                your property with our professional tree planting services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/arborist-consultations/">Arborist
                                    Consultations: </Link></strong>
                                Consult with our certified arborists for personalized tree care recommendations.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/land-clearing/">Land Clearing:</Link></strong> Prepare
                                your land for
                                development or landscaping projects with our efficient land clearing services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/emergency-tree-services/">Emergency Tree Services:</Link></strong> We
                                provide fast,
                                reliable, and safe emergency tree services to address any tree-related crises swiftly.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Our Process - Ensuring Healthy Trees, Every Step of the Way
                        </Heading>
                        <Text mb={4}>
                            At Hartford Tree Service, our process is designed to ensure the health and longevity of your
                            trees
                            while providing you with a seamless experience:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Initial Assessment:</strong> We begin with a thorough assessment of your trees'
                                health and
                                specific care needs.
                            </ListItem>
                            <ListItem>
                                <strong>Customized Care Plan:</strong> Based on our assessment, we create a tailored
                                care plan
                                designed to address your trees' unique requirements.
                            </ListItem>
                            <ListItem>
                                <strong>Expert Execution:</strong> Our team of certified arborists implements the care
                                plan with
                                precision and expertise, ensuring optimal results.
                            </ListItem>
                            <ListItem>
                                <strong>Quality Assurance:</strong> We conduct post-service evaluations to ensure that
                                our work meets
                                the highest standards of quality and satisfaction.
                            </ListItem>
                            <ListItem>
                                <strong>Follow-Up Care:</strong> We provide ongoing guidance and support to help you
                                maintain the
                                health and beauty of your trees for years to come.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Why Choose Hartford Tree Service?
                        </Heading>
                        <Text mb={4}>
                            In a market filled with tree care options, Hartford Tree Service stands out for several key
                            reasons
                            that resonate with our customers in the Hartford area:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Local Expertise:</strong> Our deep understanding of the local ecosystem enables
                                us to
                                provide tailored tree care solutions.
                            </ListItem>
                            <ListItem>
                                <strong>Certified Arborists:</strong> Our team consists of certified arborists dedicated
                                to
                                maintaining the health and beauty of your trees.
                            </ListItem>
                            <ListItem>
                                <strong>Customer-Centric Approach:</strong> Your satisfaction is our priority. We listen
                                to your
                                tree care needs and tailor our services accordingly.
                            </ListItem>
                            <ListItem>
                                <strong>Environmentally Conscious:</strong> We prioritize eco-friendly practices in our
                                tree care
                                services, promoting sustainability.
                            </ListItem>
                            <ListItem>
                                <strong>Comprehensive Services:</strong> From initial tree assessment to post-service
                                guidance, we
                                cover all your tree care needs.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Frequently Asked Questions (FAQs) About Tree Care
                        </Heading>
                        <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                            {faqs.map(({question, answer}, index) => (
                                <Box
                                    p={5}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    key={index}
                                >
                                    <Heading as={"h3"} fontSize="xl">{question}</Heading>
                                    <Text mt={4}>{answer}</Text>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Begin Your Journey to Healthy Trees Today
                        </Heading>
                        <Text mb={4}>
                            Ready to give your trees the care they deserve? Contact Hartford Tree Service today to
                            schedule a
                            consultation and take the first step toward vibrant, healthy trees that enhance the beauty
                            and value of
                            your property.
                        </Text>
                        <Button colorScheme="green" size="md" mb={4} as={'a'} href={telLink}>
                            Contact Us Now
                        </Button>
                    </Box>
                </Container>
            </>,
        footerHeading: "Connect with Hartford Tree Service CT",
        footerText: () => (
            <>
                For top-tier tree care services in Hartford and nearby areas, contact us
                today. Call or use our <Link href={"/contact"}>online form</Link> for
                general inquiries. Our dedicated team is ready to assist with all your
                tree maintenance needs.
            </>
        ),
        footerText2: "Ensuring the Health and Beauty of Your Trees in Hartford",
        servicesHeader:
            "Comprehensive Tree Services in Hartford for All Your Arboricultural Requirements",
        servicesSubtitle: () => (
            <Text color="muted" fontSize={{base: "lg", md: "xl"}}>
                Discover our <Link href={"/services"}>full range of services</Link> –
                from urgent tree removal to routine tree health care, including{" "}
                <a
                    href="https://www.hartfordtreeservicect.com/lawn-care"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    lawn care service
                </a>
                , Hartford Tree Service CT is your go-to for all tree-related needs.
            </Text>
        ),
        featuredServices: [
            {
                name: 'Tree Pruning',
                description: 'Our tree pruning services enhance the health and appearance of your trees, contributing significantly to the overall charm of Hartford landscapes.',
                icon: BiCut,
                path: '/tree-pruning/',
                linkTitle: 'Tree Pruning'
            },
            {
                name: 'Tree Trimming',
                description: 'Our tree trimming services are expertly designed to maintain the shape and health of your trees, playing a crucial role in beautifying Hartford\'s natural scenery.',
                icon: FaCut,
                path: '/tree-trimming/',
                linkTitle: 'Tree Trimming'
            },
            {
                name: 'Stump Removal',
                description: 'Expert in stump removal, we clear your yard of unwanted tree stumps safely and effectively, enhancing the functionality and aesthetics of your property.',
                icon: GiTreeRoots,
                path: '/stump-grinding/',
                linkTitle: 'Stump Removal'
            },
            {
                name: 'Tree Planting',
                description: 'Our tree planting service ensures the careful handling and planting of trees, perfect for enhancing the greenery and biodiversity of Hartford’s neighborhoods.',
                icon: GiTreeDoor,
                path: '/tree-planting/',
                linkTitle: 'Tree Planting'
            },
            {
                name: 'Tree Care Consultation',
                description: 'Seek expert advice on tree care with our professional consultations, offering guidance and strategies for optimal tree health and maintenance.',
                icon: FaConciergeBell,
                path: '/tree-health-consulting/',
                linkTitle: 'Tree Care Consultation'
            },
            {
                name: 'Urgent Tree Services',
                description: 'Ready to respond to emergency tree situations, our team provides swift and dependable services for urgent tree care needs in Hartford.',
                icon: MdOutlineEmergency,
                path: '/emergency-tree-services/',
                linkTitle: 'Urgent Tree Services'
            },
            {
                name: 'Arborist Expertise',
                description: 'Benefit from our arborist’s in-depth knowledge for tree health assessments, risk management, and sustainable tree care practices.',
                icon: MdOutlineNaturePeople,
                path: '/arborist-consultations/',
                linkTitle: 'Arborist Expertise'
            },
            {
                name: 'Property Clearing',
                description: 'Our property clearing services are ideal for preparing land for construction or landscaping projects, with a focus on efficiency and environmental friendliness.',
                icon: FaRegHandScissors,
                path: '/land-clearing/',
                linkTitle: 'Property Clearing'
            }
        ],
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Expert Arborists:</b> Our team of certified arborists is highly
                        skilled and dedicated to providing exceptional tree care services.
                        As Hartford locals, we understand the specific needs of our region
                        and can address any challenges unique to our local ecosystem.
                        Whether you require tree maintenance, diagnosis, or consultation,
                        our expert arborists have you covered.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Safe and Efficient Tree Services:</b> Safety is our top priority
                        in every tree service we provide. Our team adheres to strict safety
                        protocols and utilizes advanced technology to ensure not only the
                        safety of our crew but also the efficient completion of the job. You
                        can trust us for professional tree services that prioritize both
                        safety and efficiency.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Competitive Pricing:</b> We believe in fair and competitive
                        pricing for our top-quality tree care services. Our transparent
                        pricing model ensures that you receive value for your investment. If
                        you're looking for reliable and affordable tree care, contact us for
                        a quote today! We are committed to providing cost-effective
                        solutions without compromising on the quality of our services.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Comprehensive Tree Care:</b> From tree removal to preservation
                        and management, our comprehensive tree care services cater to all
                        your needs. Visit our <Link href="/" target="_blank" rel="noreferrer">
                        website</Link> for detailed information on pruning, stump grinding, storm damage
                        recovery, and more. Whether you are a homeowner or a business owner,
                        our experienced team has the expertise to handle various
                        tree-related challenges.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>Local Expertise:</b> As Hartford natives, our deep-rooted
                        connection to the local environment gives us unparalleled expertise
                        in dealing with the trees and landscapes of our region. Trust us for
                        knowledgeable and effective tree care tailored to the unique
                        characteristics of Hartford's greenery. Our local expertise ensures
                        that we understand the specific needs of your property and can
                        provide customized solutions for optimal tree health.
                    </Text>
                ),
            },
        ],
    },
    careers: {
        url: "https://www.hartfordtreeservicect.com/careers/",
        metaData: {
            title: "Career Opportunities at Hartford Tree Service CT - Join Our Team",
            description:
                "Seeking a career in tree care? Hartford Tree Service CT offers exciting opportunities in Hartford, CT. We welcome dedicated individuals to our expert tree care team.",
        },
        h1: "Join Our Growing Team at Hartford Tree Service CT",
        h2: [
            "Why Build Your Career with Hartford Tree Service CT?",
            "Explore Our Current Job Openings in Tree Care",
            "Our Dedication to Employee Growth and Satisfaction",
        ],
        headingText: {
            whyJoin:
                "Be part of a team where your passion for arboriculture and the environment is deeply appreciated. We're dedicated to top-tier tree care services in Hartford, CT.",
            openings:
                "Check out our latest job openings for roles including arborists, tree climbers, and support staff. Find your perfect match in a career with trees.",
            commitment:
                "At Hartford Tree Service CT, we invest in our employees' skills and provide a nurturing and dynamic work environment. Your professional development is key to our shared success.",
        },
        content: [
            <Text>
                Welcome to the Careers page at{" "}
                <Link href={"/"}>Hartford Tree Service CT</Link>. Here, your career in
                arboriculture can grow. We're a team dedicated to excellence in tree
                services, contributing significantly to Hartford's community.
            </Text>,
            <Text>
                Strength at <Link href={"/"}>Hartford Tree Service CT</Link> comes from
                our team. We seek individuals who are committed to superior tree care.
                Whether you're a seasoned arborist or just starting out, we provide an
                environment where your talents are valued and developed.
            </Text>,
            <Text>
                Our team members enjoy a culture that fosters personal and professional
                development. We offer extensive training, competitive salaries, and
                access to state-of-the-art tree care equipment. Our aim is to ensure a
                safe, rewarding, and enjoyable workplace for our entire team.
            </Text>,
            <Text>
                As an integral part of the Hartford community, we pride ourselves on
                delivering services with integrity and professionalism. Our commitment
                to excellence extends to our team members, whom we support with a
                positive work culture, teamwork, and career progression opportunities.
            </Text>,
            <Text>
                Ready to join a leading tree service team in Hartford, CT? View our
                current job openings and find your place in our growing company. We have
                roles for everyone, from expert tree climbers to customer service
                professionals. Join us at Hartford Tree Service CT.
            </Text>,
            <Text>
                <Link href="/careers/">Find your career opportunity</Link> with us. Be a part of a team that's committed
                to enhancing Hartford's urban forest. At <Link href={"/"}>Hartford Tree Service CT</Link>, we're
                not just a company - we're a community. Apply today to advance your
                career in tree care.
            </Text>,
        ],
    },
    about: {
        url: "https://www.hartfordtreeservicesct.com/about/",
        metaData: {
            title:
                "Discover Hartford Tree Services: Your Trusted Tree Care Partner in Hartford, CT",
            description:
                "Learn about our commitment to providing exceptional tree care services in Hartford. From arborist expertise to eco-friendly practices, Hartford Tree Services is here for you.",
        },
        h1: "About Hartford Tree Services in Hartford, CT",
        h2: [
            "Dedicated Tree Care Experts at Your Service",
            "Our Mission: Sustainable, Safe, and Efficient Tree Care",
            "A Team of Certified Arborists and Tree Care Professionals",
            "Committed to Eco-Friendly Practices and Customer Satisfaction",
        ],
        headingText: {
            commitment:
                "Committed to excellence, Hartford Tree Services offers professional tree care, ensuring the beauty and health of Hartford's urban forest.",
            team: "Our team comprises passionate arborists and tree care experts, dedicated to delivering high-quality service on every project.",
            services:
                "We offer a full range of tree care services, from pruning and maintenance to emergency tree removals and health assessments. Employing the latest techniques and eco-friendly practices, our services are designed to ensure the health and safety of your trees and property. Whether it's a residential garden or a commercial landscape, our team is equipped to handle all your tree care needs.",
        },
        services: [
            {
                id: "0",
                title: "Tree Pruning",
                href: "/tree-pruning",
                icon: AiOutlineScissor,
                description: (
                    <Text>
                        Our specialized pruning and trimming services focus on enhancing the
                        health and aesthetic appeal of your trees. Using careful techniques,
                        we ensure proper tree shape and growth, while removing any hazardous
                        or unhealthy branches. Our certified arborists take a tailored
                        approach, considering the unique needs of each tree to promote
                        longevity and vitality.
                    </Text>
                ),
            },
            {
                id: "1",
                title: "Tree Removal",
                href: "/tree-removal",
                icon: GiChoppedSkull,
                description: (
                    <Text>
                        We provide safe and efficient tree removal services for trees that
                        are diseased, dangerous, or inappropriately located. Our team uses
                        state-of-the-art equipment and follows strict safety protocols to
                        ensure a smooth operation with minimal disruption. From initial
                        assessment to complete removal and site cleanup, we prioritize
                        safety and professionalism.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Stump Grinding & Removal",
                href: "/stump-grinding",
                icon: GiStumpRegrowth,
                description: (
                    <Text>
                        Stump grinding is an essential part of tree removal, helping to
                        reclaim space and enhance the safety and aesthetics of your
                        property. Our team efficiently removes stumps, leaving the area
                        ready for new growth or landscaping projects. With our specialized
                        equipment, we ensure a thorough grind, minimizing the visibility of
                        the stump and preparing the ground for your future landscaping
                        endeavors.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Emergency Tree Services",
                href: "/emergency-tree-services",
                icon: MdEmergencyShare,
                description: (
                    <Text>
                        Our emergency tree services are available 24/7 to address urgent
                        situations like storm damage or hazardous tree conditions. We
                        respond promptly to ensure quick and safe resolution, minimizing any
                        risk to your property. Whether it's fallen limbs, uprooted trees, or
                        other storm-related issues, our team is equipped to handle emergency
                        situations with efficiency and expertise.
                    </Text>
                ),
            },
            {
                id: "4",
                title: "Tree Health Assessments",
                href: "/tree-health-assessments",
                icon: MdHealthAndSafety,
                description: (
                    <Text>
                        Our arborists conduct thorough health assessments to diagnose and
                        treat any tree diseases or pest infestations. We provide expert
                        advice and treatment plans to maintain the health and longevity of
                        your trees. From identifying early signs of diseases to recommending
                        targeted treatments, our goal is to keep your trees thriving and
                        resilient.
                    </Text>
                ),
            },
            {
                id: "5",
                title: "Arborist Consultations",
                href: "/arborist-consultations",
                icon: BiConversation,
                description: (
                    <Text>
                        Our certified arborists offer professional consultations for tree
                        care advice, risk assessment, and management strategies. Whether
                        it's for residential or commercial properties, we provide expert
                        guidance tailored to your needs. From selecting the right tree
                        species for your landscape to addressing specific concerns or
                        challenges, our arborists are here to ensure your trees receive the
                        best care possible.
                    </Text>
                ),
            },
            {
                id: "6",
                title: "Land Clearing & Lot Preparation",
                href: "/land-clearing",
                icon: GiLandMine,
                description: (
                    <Text>
                        We offer comprehensive land clearing services for construction,
                        landscaping, or agricultural projects. Our team ensures efficient
                        and eco-friendly clearing of trees, shrubs, and other vegetation.
                        With a focus on sustainability, we strive to minimize environmental
                        impact while preparing your land for development. Whether it's a
                        small lot or a large area, we have the expertise and equipment for
                        effective land clearing.
                    </Text>
                ),
            },
        ],
    },
    testimonials: {
        h1: "Testimonials",
        heading:
            "Hear from Our Satisfied Customers – Hartford Tree Services Delivers Exceptional Tree Care",
        testimonials: [
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a042581f4e29026704d",
                name: "Sarah P.",
                title: "Hartford, CT",
                quote:
                    '"I was thoroughly impressed with Hartford Tree Services. They did an outstanding job with the tree pruning in my yard. Their team was professional, efficient, and left everything looking pristine."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0425d",
                name: "Alex J.",
                title: "Hartford, CT",
                quote:
                    '"After a severe storm, we needed emergency tree removal. Hartford Tree Services responded quickly and handled the situation with utmost professionalism. Their prompt and safe service was truly commendable."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0804d",
                name: "Mia T.",
                title: "Hartford, CT",
                quote:
                    '"Hartford Tree Services transformed our outdoor space with their landscaping and tree planting services. Their attention to detail and knowledge of local tree species made a significant difference. Highly recommend their services!"',
            },
        ],
    },
    services: {
        metaData: {
            title:
                "Professional Tree Care Services in Hartford, CT | Hartford Tree Services",
            description:
                "Explore our range of tree care services in Hartford, CT. From precise pruning to safe tree removal, our experienced team is here to cater to your arboricultural needs.",
        },
        h1: "Expert Tree Services in Hartford, CT | Hartford Tree Services",
        h2: "Our Tree Care Offerings",
        subtext:
            "At Hartford Tree Services, we’re committed to enhancing your landscape’s health and beauty with our array of professional tree care services.",
        footerHeading: "Elevate Your Landscape with Us",
        footerText2: () => (
            <>
                Get in touch at <Link href={telLink}>{phoneNumberWithDashes}</Link> or
                use our online contact form for a free consultation. Experience the best
                in tree care with Hartford Tree Services!
            </>
        ),
        footerText: () => (
            <>
                Your trees’ well-being is our priority. For expert tree care in
                Hartford, CT, rely on Hartford Tree Services. We’re just a call away!
            </>
        ),
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        Comprehensive Tree Care in Hartford, CT
                    </Heading>
                    <Text>
                        Our team at Hartford Tree Services specializes in a range of tree
                        care services, each performed with precision and care. Whether you
                        require routine maintenance or specialized tree treatment, we are
                        equipped to provide top-notch services.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Hartford Trusts Our Tree Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Expert Arborists:</b> Our team comprises certified arborists
                                who bring a wealth of knowledge and experience to every project.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Modern Techniques:</b> We utilize the latest tree care
                                methods and equipment for efficient and effective service.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Emergency Tree Care:</b> Available 24/7, we’re ready to
                                respond to urgent tree care needs and emergencies.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Fair Pricing:</b> We offer competitive pricing for our
                                high-quality tree care services, ensuring great value for our
                                clients.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Local Knowledge:</b> Understanding the specific needs of
                                Hartford’s trees, we tailor our services for the best results.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        A Full Range of Tree Care Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Tree Trimming and Pruning:</b> Enhancing tree health and
                                aesthetics through strategic trimming and pruning.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Safe Tree Removal:</b> Removing trees safely, whether due to
                                damage, disease, or landscaping changes.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Stump Grinding:</b> Eliminating tree stumps to improve safety
                                and landscape aesthetics.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Tree Health Assessments:</b> Comprehensive evaluations by our
                                arborists to ensure your trees’ optimal health.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Land Clearing:</b> Preparing your property for new projects
                                with our efficient land clearing services.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Your Go-To for Tree Care Excellence
                    </Heading>
                    <Text>
                        Trust Hartford Tree Services for all your tree care needs. Contact
                        us today for unparalleled service in Hartford, CT.
                    </Text>
                </Stack>
            </Box>
        ),
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Tree Pruning and Trimming:</b> Our skilled arborists meticulously
                        prune and trim trees to promote healthy growth and enhance their
                        natural beauty. We address specific needs like removing dead
                        branches, shaping the canopy, and ensuring safety clearance.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Stump Grinding:</b> We provide efficient stump grinding services
                        to eliminate tripping hazards and clear the way for new plantings or
                        landscaping projects. Our advanced equipment ensures thorough and
                        safe stump removal.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Tree Removal:</b> Our team safely removes trees that are
                        diseased, damaged, or unsuitably located. We perform thorough risk
                        assessments to ensure the safety of your property and our crew
                        during the removal process.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Emergency Tree Services:</b> Available 24/7, we respond promptly
                        to emergency situations, including storm damage, fallen trees, or
                        other urgent tree care needs, ensuring quick and safe resolution.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>Tree Health Assessments:</b> We conduct detailed evaluations to
                        diagnose the health of your trees, identify any issues, and
                        recommend appropriate treatments or care plans to ensure their
                        long-term vitality.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>Land Clearing:</b> Our land clearing services are ideal for
                        preparing your property for construction or landscaping projects. We
                        efficiently clear trees, shrubs, and other vegetation while adhering
                        to environmental safety standards.
                    </Text>
                ),
            },
            {
                id: "6",
                text: () => (
                    <Text>
                        <b>Arborist Consultations:</b> Our certified arborists provide
                        expert consultations for tree care, risk assessment, and management
                        strategies. Whether you have concerns about tree health or need
                        advice on tree maintenance, our team is here to offer their
                        expertise.
                    </Text>
                ),
            },
            {
                id: "7",
                text: () => (
                    <Text>
                        <b>Tree Planting and Transplanting:</b> We assist in selecting and
                        planting new trees, as well as safely transplanting existing trees.
                        Our knowledge of local species and conditions ensures the best care
                        for tree establishment and growth.
                    </Text>
                ),
            },
            {
                id: "8",
                text: () => (
                    <Text>
                        <b>Customized Tree Care Plans:</b> Tailored to the unique needs of
                        each landscape, we develop comprehensive tree care plans that cover
                        everything from fertilization to pest control, ensuring the health
                        and longevity of your trees.
                    </Text>
                ),
            },
            {
                id: "9",
                text: () => (
                    <Text>
                        <b>Eco-Friendly Tree Solutions:</b> Our approach to tree care is
                        rooted in sustainability. We use environmentally responsible
                        practices to minimize impact and promote the well-being of your
                        landscape and the broader ecosystem.
                    </Text>
                ),
            },
        ],
        whyh2: "Why Choose Hartford Tree Services?",
        whySubtext: () => (
            <>
                Our commitment to excellence and customer satisfaction sets us apart in
                Hartford’s tree care industry. Contact us at{" "}
                <Link href={telLink} textDecoration={"underline"}>
                    {phoneNumber}
                </Link>{" "}
                to experience the difference with Hartford Tree Services.
            </>
        ),
        whyServices: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Experienced Professionals:</b> Our dedicated team consists of
                        trained professionals with years of expertise in tree care, ready to
                        handle any project, big or small.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Unmatched Service:</b> We prioritize our customers and their
                        needs, ensuring availability throughout the project and working
                        diligently until their satisfaction.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Reliable and Efficient:</b> We have built a strong reputation
                        through years of consistent, top-notch service to our community.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Local Experts:</b> Our team's deep understanding of Hartford's
                        local botany, topography, and landscape provides our customers with
                        a level of expertise that is unrivaled.
                    </Text>
                ),
            },
        ],
    },
    contact: {
        url: "https://www.hartfordtreeservicesct.com/contact/",
        metaData: {
            title:
                "Contact Hartford Tree Services | Expert Tree Care in Hartford, CT",
            description:
                "Reach out to Hartford Tree Services for top-notch tree care in Hartford, CT. Our team is ready to assist with your tree care requirements.",
        },
        h1: "Contact Hartford Tree Services",
        subtitle:
            "Thank you for considering Hartford Tree Services. We are committed to providing reliable tree care services in Hartford, CT. Our professional team is here to assist with all your tree care needs.",
        sectionHeading: "Get in Touch with Hartford Tree Services",
        signUpDescription:
            "To reach us online, please fill out the form below. We will respond promptly to your inquiry.",
        feedbackText:
            "Your feedback is important to us. For any questions or concerns, please don’t hesitate to reach out. We are dedicated to ensuring your complete satisfaction.",
        closingText: <>Delivering expert tree care services in Hartford, CT.</>,
        valueProps: [
            {
                title: "Certified Arborists:",
                description:
                    "Our team of certified arborists has extensive experience and knowledge, ensuring top-quality tree care and advice.",
            },
            {
                title: "Comprehensive Tree Services:",
                description:
                    "We offer a full range of tree care services, from maintenance and pruning to removal and stump grinding, catering to all your tree care needs.",
            },
            {
                title: "State-of-the-Art Equipment:",
                description:
                    "Our use of modern, high-quality equipment allows us to provide efficient and safe tree care services.",
            },
            {
                title: "Eco-Conscious Approach:",
                description:
                    "We are committed to sustainable practices, focusing on the health of your trees and the environment.",
            },
            {
                title: "Customer-Focused Service:",
                description:
                    "At Hartford Tree Services, we prioritize customer satisfaction, ensuring a seamless and positive experience from start to finish.",
            },
        ],
    },
    blog: {
        url: "https://www.hartfordtreeservicesct.com/blog/",
        metaData: {
            title:
                "Hartford Tree Services Blog: Insights & Tips on Tree Care | Hartford, CT",
            description:
                "Explore expert advice on tree care, maintenance, and safety from Hartford Tree Services. Stay informed with our latest blog posts.",
        },
        h1: "Hartford Tree Services Blog - Tips and Insights",
        h2: "Our Blog",
        heading:
            "At Hartford Tree Services, we aim to empower our clients with knowledge about tree care and maintenance. Our blog is a resource for anyone looking to understand more about tree health, safety, and the latest in arboriculture. We are here to support your tree care journey.",
        posts,
    },
    treePruning: {
        url: "https://www.hartfordtreeservicesct.com/tree-pruning",
        metaData: {
            title:
                "Expert Tree Pruning Services in Hartford, CT | Hartford Tree Services",
            description:
                "Enhance the health and appearance of your trees with our professional tree pruning services in Hartford, CT.",
        },
        h1: "Tree Pruning Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Pruning is essential for tree health and aesthetics. Our skilled arborists use precise techniques to promote healthy tree growth and maintain structural integrity.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h3"}>Our Tree Pruning Approach</Heading>
                    <Text>We specialize in:</Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            Health-focused Pruning: Removing dead or diseased branches to
                            enhance overall tree health.
                        </ListItem>
                        <ListItem>
                            Structural Pruning: Encouraging strong tree growth patterns for
                            long-term stability and aesthetics.
                        </ListItem>
                        <ListItem>
                            Safety Pruning: Addressing potential hazards, such as overhanging
                            limbs near buildings or walkways.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        Contact Hartford Tree Services for professional tree pruning in
                        Hartford, CT.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    treeRemoval: {
        url: "https://www.hartfordtreeservicesct.com/tree-removal/",
        metaData: {
            title:
                "Safe and Professional Tree Removal in Hartford, CT | Hartford Tree Services",
            description:
                "Need to remove a tree? Our team in Hartford, CT, offers safe and efficient tree removal services.",
        },
        h1: "Best Stump Removal Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Our expert team handles stump removals with the utmost safety and efficiency, whether for landscape management or safety concerns.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h3"}>Comprehensive Stump Removal Services</Heading>
                    <Text>
                        When it comes to stump removal, Hartford Tree Services offers
                        comprehensive services to ensure a smooth and efficient process. Our
                        services include:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Risk Assessment:</b> Careful evaluation of trees to determine
                            the necessity of stump removal. Our expert team assesses the risks
                            associated with remaining tree stumps, considering factors such as
                            safety, aesthetics, and potential hazards.
                        </ListItem>
                        <ListItem>
                            <b>Safe Removal Techniques:</b> We utilize the latest equipment
                            and methods to ensure a safe and efficient stump removal process.
                            Our team is trained in advanced techniques to minimize disruption
                            to your property while effectively removing tree stumps of any
                            size.
                        </ListItem>
                        <ListItem>
                            <b>Site Cleanup:</b> After stump removal, we ensure that the area
                            is left clean and tidy. Our commitment to thorough site cleanup
                            means you can enjoy your rejuvenated landscape without the hassle
                            of debris or leftover materials.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        For safe and reliable tree stump removal in Hartford, CT, rely on
                        Hartford Tree Services. We combine expertise, state-of-the-art
                        equipment, and a commitment to customer satisfaction to deliver
                        top-notch stump removal services tailored to your needs.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    treeTrimming: {
        url: "https://www.hartfordtreeservicect.com/tree-trimming/",
        metaData: {
            title:
                "Professional Tree Trimming in Hartford, CT | Hartford Tree Service CT",
            description:
                "Hartford Tree Service CT offers expert tree trimming services, enhancing the health and aesthetics of your trees. Reach out for exceptional tree care in Hartford.",
        },
        h1: "Expert Tree Trimming Services in Hartford, CT | Hartford Tree Service CT",
        subtitle:
            "Beautifully trimmed trees can significantly improve the appearance and value of your property. Hartford Tree Service CT provides expert tree trimming services to keep your trees healthy and attractive all year round.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h2"}>Our Tree Trimming Services</Heading>
                    <Text>
                        At Hartford Tree Service CT, we take pride in delivering superior
                        tree trimming services at competitive rates. Our dedicated team of
                        professionals is committed to enhancing the health, structure,
                        aesthetics, and safety of your trees. Here's what we offer:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Health Trimming:</b> Our experienced team carefully trims away
                            unhealthy or infested branches, promoting the overall health of
                            the tree. Regular health trimming is essential for preventing the
                            spread of diseases and ensuring the longevity of your trees.
                        </ListItem>
                        <ListItem>
                            <b>Structural Trimming:</b> We specialize in promoting a robust
                            structure for your trees, reducing the risk of storm damage and
                            branch failures. Structural trimming is crucial for maintaining
                            the integrity and stability of your trees, especially during
                            adverse weather conditions.
                        </ListItem>
                        <ListItem>
                            <b>Aesthetic Trimming:</b> Enhance the visual appeal of your
                            property with our aesthetic tree trimming services. Our team
                            tailors the trimming process to beautify your landscape, ensuring
                            that your trees complement the overall aesthetics of your outdoor
                            space.
                        </ListItem>
                        <ListItem>
                            <b>Safety Trimming:</b> Your safety is our priority. We eliminate
                            branches that could pose a risk, ensuring your property remains a
                            safe environment. Safety trimming is essential for preventing
                            accidents and minimizing potential hazards associated with
                            overhanging or weakened branches.
                        </ListItem>
                    </UnorderedList>
                </Stack>

                <Stack spacing={3} py={5}>
                    <Heading as={"h3"}>
                        Choose Hartford Tree Service CT for Your Tree Trimming
                    </Heading>
                    <Text>
                        When it comes to tree trimming, Hartford Tree Service CT stands out
                        as your top choice. Our expertise in Hartford's unique environment,
                        coupled with our strong reputation, ensures that you receive
                        exceptional service. Here's why you should choose us:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Qualified Arborists:</b> Our team of qualified arborists
                            possesses the expertise to trim your trees safely and effectively.
                            With in-depth knowledge of tree species and growth patterns, we
                            ensure precise and skillful trimming to promote the health and
                            aesthetics of your trees.
                        </ListItem>
                        <ListItem>
                            <b>High-Quality Service:</b> Our commitment to quality is evident
                            in our customer reviews and consistent track record. We take pride
                            in delivering high-quality tree trimming services that meet and
                            exceed the expectations of our valued clients. Your satisfaction
                            is our priority.
                        </ListItem>
                        <ListItem>
                            <b>Local Knowledge:</b> Our familiarity with Hartford's unique
                            flora allows us to provide specialized care tailored to your
                            landscape's needs. We understand the specific challenges and
                            characteristics of the local environment, ensuring that our tree
                            trimming services are well-suited to the conditions in Hartford.
                        </ListItem>
                        <ListItem>
                            <b>Focused on Satisfaction:</b> Customer satisfaction is at the
                            forefront of our priorities. We strive to make our services as
                            seamless as possible for you, from the initial consultation to the
                            completion of the tree trimming process. Your feedback and
                            satisfaction drive our commitment to excellence.
                        </ListItem>
                    </UnorderedList>
                </Stack>

                <Stack spacing={3} py={5}>
                    <Heading as={"h3"}>
                        Top-Rated Tree Trimming Services in Hartford, CT
                    </Heading>
                    <Text>
                        Contact us today at{" "}
                        <Link href={telLink}>{phoneNumberWithDashes}</Link> to schedule your
                        tree trimming service. Discover the Hartford Tree Service CT
                        difference!
                    </Text>
                </Stack>
            </Box>
        ),
    },
    stumpGrinding: {
        url: "https://www.hartfordtreeservicesct.com/stump-grinding",
        metaData: {
            title:
                "Professional Stump Grinding in Hartford, CT | Hartford Tree Services",
            description:
                "Efficient stump grinding services in Hartford, CT. Remove unwanted stumps and enhance your landscape with Hartford Tree Services.",
        },
        h1: "Stump Grinding Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Stump grinding is an essential step in tree removal, helping to restore the aesthetics and functionality of your landscape.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h3"}>Effective Stump Removal</Heading>
                    <Text>Our services include:</Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            Advanced Stump Grinding: Utilizing top-tier equipment to
                            completely remove tree stumps from your property.
                        </ListItem>
                        <ListItem>
                            Site Restoration: Preparing the ground for replanting or
                            landscaping post-removal.
                        </ListItem>
                        <ListItem>
                            Debris Management: Ensuring a clean and tidy area after stump
                            grinding.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        For comprehensive stump grinding services in Hartford, CT, choose
                        Hartford Tree Services.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    treeHealthConsulting: {
        url: "https://www.hartfordtreeservicesct.com/tree-health-consulting",
        metaData: {
            title:
                "Expert Tree Health Consulting in Hartford, CT | Hartford Tree Services",
            description:
                "Ensure the health and longevity of your trees with our tree health consulting services in Hartford, CT.",
        },
        h1: "Expert Tree Health Consulting Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Our expert arborists offer comprehensive tree health consulting to maintain and enhance the vitality of your trees.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h2"}>Professional Tree Health Assessment</Heading>
                    <Text>
                        At Hartford Tree Services, our professional tree health assessment
                        services are designed to ensure the vitality and well-being of your
                        trees. Our consulting services include:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Detailed Health Assessments:</b> We conduct thorough
                            evaluations to assess the condition of your trees, identifying any
                            potential health issues. Our expert arborists use their knowledge
                            and experience to provide a detailed analysis, addressing concerns
                            and recommending appropriate solutions.
                        </ListItem>
                        <ListItem>
                            <b>Disease and Pest Management:</b> When it comes to tree diseases
                            and pest infestations, our team has you covered. We offer
                            effective solutions for managing and mitigating the impact of
                            diseases and pests on your trees. Our goal is to protect the
                            health and longevity of your green assets.
                        </ListItem>
                        <ListItem>
                            <b>Preventive Care Strategies:</b> We believe in proactive tree
                            care. Our team develops comprehensive, long-term care plans to
                            ensure the ongoing health and beauty of your trees. Through
                            preventive measures, we aim to minimize the risk of potential
                            issues and promote the resilience of your tree canopy.
                        </ListItem>
                        <ListItem>
                            <b>Soil Health Analysis:</b> In addition to tree assessments, we
                            analyze the health of the soil surrounding your trees. Healthy
                            soil is essential for robust tree growth, and our experts provide
                            insights and recommendations for improving soil quality.
                        </ListItem>
                        <ListItem>
                            <b>Seasonal Maintenance Tips:</b> Our consulting services extend
                            to providing seasonal maintenance tips. We guide you on the
                            specific care your trees may need during different seasons,
                            ensuring year-round health and vitality.
                        </ListItem>
                        <ListItem>
                            <b>Customized Treatment Plans:</b> Based on our assessments, we
                            create customized treatment plans tailored to the unique needs of
                            your trees. These plans may include pruning schedules,
                            fertilization programs, and other interventions to address
                            specific health concerns.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        For expert tree health consulting in Hartford, CT, trust Hartford
                        Tree Services. Whether you need a one-time assessment or ongoing
                        care plans, our dedicated team is here to safeguard the health and
                        vitality of your trees.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    emergencyTreeServices: {
        url: "https://www.hartfordtreeservicesct.com/emergency-tree-services",
        metaData: {
            title:
                "24/7 Emergency Tree Services in Hartford, CT | Hartford Tree Services",
            description:
                "Immediate response for emergency tree care in Hartford, CT. Hartford Tree Services offers prompt and safe solutions for urgent tree needs.",
        },
        h1: "Quick Emergency Tree Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "We provide fast, reliable, and safe emergency tree services to address any tree-related crises swiftly.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h3"}>Rapid Response Tree Care</Heading>
                    <Text>
                        When emergencies strike, Hartford Tree Services is your reliable
                        partner for rapid response tree care. Our emergency services cover a
                        range of situations to address urgent tree care needs. Our services
                        include:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Storm Damage Response:</b> We provide quick action to manage
                            trees damaged by severe weather. Whether it's fallen branches or
                            uprooted trees, our team is equipped to assess the damage,
                            prioritize safety, and implement prompt solutions to restore your
                            property.
                        </ListItem>
                        <ListItem>
                            <b>Hazardous Tree Removal:</b> Urgent removal of trees that pose
                            immediate risks to property and safety. Our experienced arborists
                            are trained to assess hazardous situations and perform safe and
                            efficient tree removal to eliminate potential dangers.
                        </ListItem>
                        <ListItem>
                            <b>24/7 Availability:</b> We understand that emergencies can
                            happen at any time. That's why we are ready to respond to
                            tree-related emergencies 24/7. Whether it's during the day, night,
                            or on weekends, you can count on us to be there when you need us
                            most.
                        </ListItem>
                        <ListItem>
                            <b>Emergency Tree Pruning:</b> In addition to removal, we offer
                            emergency tree pruning to address immediate safety concerns. This
                            includes the removal of overhanging branches, damaged limbs, and
                            other hazards that could pose a threat.
                        </ListItem>
                        <ListItem>
                            <b>Post-Emergency Cleanup:</b> After addressing the immediate
                            concerns, we ensure a thorough post-emergency cleanup. Our team
                            removes debris, clears the area, and takes necessary steps to
                            prevent further damage, restoring safety and order to your
                            property.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        In case of a tree emergency in Hartford, CT, contact Hartford Tree
                        Services immediately. Our dedicated team is ready to provide swift
                        and effective solutions to protect your property and ensure the
                        safety of your surroundings.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    landClearing: {
        url: "https://www.hartfordtreeservicesct.com/land-clearing",
        metaData: {
            title:
                "Efficient Land Clearing Services in Hartford, CT | Hartford Tree Services",
            description:
                "Prepare your property for new projects with our land clearing services in Hartford, CT.",
        },
        h1: "Best Land Clearing Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Our land clearing services are designed to efficiently prepare your land for construction, landscaping, or other projects.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h2"}>Comprehensive Land Preparation</Heading>
                    <Text>
                        Trust Hartford Tree Services for professional land preparation
                        services designed to transform your landscape. Our comprehensive
                        services include:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Tree and Brush Removal:</b> We specialize in clearing the land
                            of trees, shrubs, and underbrush. Our team employs efficient
                            techniques to remove vegetation, providing a clean canvas for your
                            project, whether it's for construction, landscaping, or other
                            purposes.
                        </ListItem>
                        <ListItem>
                            <b>Stump Grinding and Removal:</b> Ensuring a smooth terrain
                            post-clearing is essential for the success of your project. Our
                            stump grinding and removal services effectively eliminate
                            unsightly stumps, creating a level surface for future landscaping
                            or development.
                        </ListItem>
                        <ListItem>
                            <b>Eco-Friendly Approach:</b> At Hartford Tree Services, we
                            conduct land clearing with a focus on environmental
                            sustainability. Our eco-friendly practices include responsible
                            disposal of cleared materials, recycling when possible, and
                            minimizing the environmental impact of our land preparation
                            services.
                        </ListItem>
                        <ListItem>
                            <b>Grading and Leveling:</b> To ensure optimal use of your cleared
                            land, we provide grading and leveling services. Our team utilizes
                            precision equipment to create a smooth and even surface, preparing
                            the area for construction, gardening, or other intended purposes.
                        </ListItem>
                        <ListItem>
                            <b>Site Preparation Consultation:</b> We offer expert consultation
                            to help you plan the site preparation process. Our team assesses
                            your specific needs, recommends the most suitable land preparation
                            methods, and provides insights to optimize the functionality and
                            aesthetics of your cleared land.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        For professional land clearing services in Hartford, CT, trust
                        Hartford Tree Services. We combine expertise, state-of-the-art
                        equipment, and a commitment to environmental stewardship to deliver
                        comprehensive land preparation solutions tailored to your project.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    arboristConsulting: {
        url: "https://www.hartfordtreeservicesct.com/arborist-consultations",
        metaData: {
            title:
                "Certified Arborist Consultations in Hartford, CT | Hartford Tree Services",
            description:
                "Expert arborist advice for your trees in Hartford, CT. Hartford Tree Services offers in-depth consultations for optimal tree health and management.",
        },
        h1: "Expert Arborist Consultation Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Our certified arborists provide comprehensive consulting services to help you manage and maintain the health of your trees effectively.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h2"}>Expert Arborist Advice</Heading>
                    <Text>
                        When you need professional guidance for your trees, turn to Hartford
                        Tree Services. Our expert arborist advice is tailored to meet your
                        specific needs and concerns. Our consultation services include:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Detailed Tree Health Assessments:</b> We conduct thorough
                            evaluations to assess the overall health of your trees. Our
                            certified arborists analyze various factors, identifying potential
                            issues and offering insights into maintaining and enhancing tree
                            health.
                        </ListItem>
                        <ListItem>
                            <b>Tree Care Strategy:</b> Receive tailored advice on tree
                            maintenance, disease prevention, and overall tree management. Our
                            arborists work closely with you to develop a customized care
                            strategy that aligns with your goals, ensuring the longevity and
                            vitality of your trees.
                        </ListItem>
                        <ListItem>
                            <b>Risk Assessment:</b> Identifying and advising on potential
                            risks associated with your trees is crucial for property safety.
                            Our arborists perform comprehensive risk assessments and provide
                            solutions to mitigate these risks, offering recommendations to
                            enhance the safety of your landscape.
                        </ListItem>
                        <ListItem>
                            <b>Seasonal Tree Care Tips:</b> In addition to assessments, we
                            offer seasonal tree care tips. Our arborists provide guidance on
                            the specific care your trees may need during different seasons,
                            helping you proactively address challenges and promote optimal
                            health.
                        </ListItem>
                        <ListItem>
                            <b>Tree Species Selection:</b> If you're planning to add new trees
                            to your landscape, our arborists assist in selecting the right
                            species. We consider factors such as soil conditions, climate, and
                            aesthetics to ensure the chosen trees thrive in their environment.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        For professional arborist consultations in Hartford, CT, contact
                        Hartford Tree Services. Our team is dedicated to providing expert
                        advice that enhances the health, beauty, and safety of your trees.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    treePlanting: {
        url: "https://www.hartfordtreeservicesct.com/tree-planting",
        metaData: {
            title:
                "Professional Tree Planting Services in Hartford, CT | Hartford Tree Services",
            description:
                "Enhance your landscape with our expert tree planting services in Hartford, CT. Trust Hartford Tree Services for healthy tree growth and proper planting.",
        },
        h1: "Comprehensive Tree Planting Services in Hartford, CT | Hartford Tree Services",
        subtitle:
            "Our tree planting services are designed to promote the healthy growth of trees, enhancing the natural beauty of your property.",
        body: (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={"h2"}>Tailored Tree Planting Solutions</Heading>
                    <Text>
                        At Hartford Tree Services, we specialize in providing tailored tree
                        planting solutions to enhance the beauty and sustainability of your
                        landscape. Our comprehensive services include:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            <b>Selection of Suitable Species:</b> We assist in choosing the
                            right trees for your landscape and environmental conditions. Our
                            expert team considers factors such as soil type, sunlight
                            exposure, and climate to ensure that the selected tree species
                            thrive in their new environment.
                        </ListItem>
                        <ListItem>
                            <b>Proper Planting Techniques:</b> Ensuring trees are planted
                            correctly is crucial for their optimal health and growth. We
                            employ proper planting techniques to provide your trees with a
                            strong foundation, promoting root development and overall
                            resilience.
                        </ListItem>
                        <ListItem>
                            <b>Post-Planting Care:</b> Our commitment doesn't end with
                            planting. We provide guidance on the proper care and maintenance
                            of newly planted trees. This includes watering schedules,
                            fertilization, and other essential practices to support the
                            establishment and long-term health of your trees.
                        </ListItem>
                        <ListItem>
                            <b>Environmental Sustainability:</b> We prioritize eco-friendly
                            practices in our tree planting solutions. Our team is dedicated to
                            promoting environmental sustainability by choosing native species
                            and implementing green initiatives in our planting processes.
                        </ListItem>
                        <ListItem>
                            <b>Seasonal Considerations:</b> Taking into account seasonal
                            variations, we plan tree planting activities at optimal times to
                            ensure successful establishment. Whether it's spring, summer,
                            fall, or winter, our team adapts planting strategies for each
                            season's unique conditions.
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        For expert tree planting services in Hartford, CT, choose Hartford
                        Tree Services. Whether you're looking to enhance your property's
                        aesthetics, promote environmental sustainability, or create a
                        welcoming green space, our team is dedicated to delivering tailored
                        solutions that align with your vision.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    404: {
        metaData: {
            title: "Page Not Found - Hartford Tree Services | Tree Care Specialists",
            description:
                "The page you’re seeking could not be found. Explore Hartford Tree Services for professional tree care solutions in Hartford, CT.",
        },
        h1: "404 - Oops, Branching Out to the Unknown!",
        subtitle:
            "Unfortunately, the page you’re looking for seems to have disappeared or doesn’t exist.",
        p: "While you’re herec, why not branch out to other parts of our site? Whether you need tree pruning, stump removal, or just some friendly advice, Hartford Tree Services is here to help. Explore our services or contact us directly for your tree care needs.",
    },
}