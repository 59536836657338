import React from "react";
import { Box, Link } from "@chakra-ui/react";

const CommunicationCard = ({ data }) => {
  const { iconClass, infoBody01, infoBody02, infoBody03, link } = data;
  if (link) {
    return (
      <Link href={link}>
        <div className="communication">
          <div className="communication_icon">
            <i className={iconClass}></i>
          </div>
          <Box className="info_body" pt={2}>
            <span>{infoBody01}</span>
            <p>{infoBody02}</p>
            <p>{infoBody03}</p>
          </Box>
        </div>
      </Link>
    );
  }
  return (
    <div className="communication">
      <div className="communication_icon">
        <i className={iconClass}></i>
      </div>
      <div className="info_body">
        <span>{infoBody01}</span>
        <p>{infoBody02}</p>
        <p>{infoBody03}</p>
      </div>
    </div>
  );
};

export default CommunicationCard;
