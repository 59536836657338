import React from "react";
import { Controller, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import TestimonialCard01 from "../../Components/TestimonialCards/TestimonialCard01";
import { SiteData } from "../../Constants/siteData";
import { MdReviews } from "react-icons/md";
// import './Testimonial.css';

const Testimonial01 = (props) => {
  return (
    <div className={`testimonial ${props.pb} `}>
      <div className="container">
        <div className="section_header text-center">
          <div className="shadow_icon">
            <MdReviews fontSize={"2rem"} color={"#FFA903"} />
          </div>
          <span className="section_sub_title">{SiteData.testimonials.h2}</span>
          <h2 className="section_title">{SiteData.testimonials.h1}</h2>
          <p className="section_desc">{SiteData.testimonials.heading}</p>
        </div>
        <div className="row">
          <div className="col-12">
            <Swiper
              className="owl_testimonial1 owl-carousel owl-theme"
              modules={[Pagination, Controller]}
              effect={"slide"}
              breakpoints={{
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}
              loop={true}
              controller={{ inverse: true }}
              spaceBetween={50}
              // centeredSlides={true}
              pagination={{
                el: ".testimonial-pagination",
                clickable: true,
                // dynamicBullets: true,
              }}
            >
              {SiteData.testimonials.testimonials.map((data) => (
                <SwiperSlide key={data.name}>
                  <TestimonialCard01 key={data.name} data={data} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <!-- Add Pagination --> */}
            {/*<div className="testimonial-pagination"></div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial01;
