import React from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { SiteData } from "../../Constants/siteData";

const PageHeader = ({ header, subtitle }) => {
  return (
    <div className="page_header">
      <div className="page_header_content">
        <div className="container">
          <Heading as={"h1"} className="heading" color={"white"}>
            {header}
          </Heading>
          <div className="breadcrumb">
            <Text color={"white"} fontSize={"2xl"}>
              {subtitle}
            </Text>
          </div>
          <Button
            as={"a"}
            href={SiteData.telLink}
            bg={"#ffa903"}
            fontSize={"18px"}
            color={"white"}
            _hover={{ bg: "#ffa903", color: "white", cursor: "pointer" }}
            p={"16px 30px"}
            border-radius={"0"}
            m={"1.5rem"}
            borderRadius={0}
            lineHeight={"26px"}
            minH={"56px"}
            textTransform={"uppercase"}
          >
            CALL US TODAY!
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
